import { Dropdown, Button, Menu, notification } from 'antd';
import CcxContextualMenuIcon from '../ccx/icons/CcxContextualMenuIcon';
import DeleteDbParametersPopover from './DeleteDbParametersPopover';
import EditDBParametersModal from './EditDBParametersModal';
import styles from './DbParametersActionsLists.module.less';
import { useState } from 'react';
import DbParameterGroupService from '../../services/DbParameterGroupService';
import CcxIconCheckCircleTwoTone from '../ccx/icons/CcxIconCheckCircleTwoTone';
import CcxIconCloseCircleTwoTone from '../ccx/icons/CcxIconCloseCircleTwoTone';
import { CopyOutlined } from '@ant-design/icons';

interface DbParametersActionsListsProps {
    record: any;
    refresh: () => void | Promise<void>;
}

const DbParametersActionsLists = ({
    record,
    refresh,
}: DbParametersActionsListsProps) => {
    const [isEditVisible, setIsEditVisible] = useState<boolean>(false);
    const [isDeleteable, setIsDeleteable] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const handleEditDbParameters = () => {
        setIsEditVisible(true);
    };

    const onSubmit = async () => {
        setIsLoading(true);
        const data = {
            name: `${record.name}_COPY`,
            database_vendor: record.databaseVendor,
            database_version: record.databaseVersion,
            database_type: record.databaseType,
            description: record.description,
            parameters: record.dbParameters,
        };

        try {
            await DbParameterGroupService.createDbParameterGroup(data);
            notification.open({
                message: 'DB Parameter Group Duplicated',
                description:
                    'Database parameter group has been duplicated successfully.',
                icon: <CcxIconCheckCircleTwoTone twoToneColor="#52c41a" />,
            });
        } catch (error) {
            notification.open({
                message: 'Failed to Create DB Parameter Group',
                description: `An error occurred while creating the database parameter group: ${error}`,
                icon: <CcxIconCloseCircleTwoTone twoToneColor="#eb2f96" />,
            });
            console.error(error);
        } finally {
            refresh();
            setIsLoading(false);
        }
    };

    const menu = (
        <Menu className={styles.DbParametersActionsListsMenu}>
            <Menu.Item
                key="edit"
                className={styles.DbParametersActionButton}
                onClick={() => handleEditDbParameters()}
            >
                <EditDBParametersModal
                    record={record}
                    refresh={refresh}
                    isEditVisible={isEditVisible}
                    setIsEditVisible={setIsEditVisible}
                    setIsLoading={setIsLoading}
                />
            </Menu.Item>
            <Menu.Item
                key="duplicate"
                className={styles.DbParametersActionButton}
                onClick={() => onSubmit()}
            >
                <Button
                    type={'link'}
                    className={styles.DuplicateDbParametersPopoverButton}
                    icon={<CopyOutlined />}
                >
                    Duplicate
                </Button>
            </Menu.Item>
            <Menu.Item
                key="delete"
                className={styles.DbParametersActionButton}
                onClick={() => {
                    if (record?.dataStores?.length === 0) setIsDeleteable(true);
                }}
            >
                <DeleteDbParametersPopover
                    record={record}
                    refresh={refresh}
                    isDeleteable={isDeleteable}
                    setIsLoading={setIsLoading}
                />
            </Menu.Item>
        </Menu>
    );

    return (
        <Dropdown overlay={menu} placement="bottomRight" trigger={['click']}>
            <Button icon={<CcxContextualMenuIcon />} loading={isLoading} />
        </Dropdown>
    );
};

export default DbParametersActionsLists;
