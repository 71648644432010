import {
    EyeInvisibleOutlined,
    EyeOutlined,
    InfoCircleOutlined,
} from '@severalnines/frontend_hub/libs/icons';
import { Radio } from 'antd';
import { Row } from 'antd';
import { Form } from 'antd';
import { Tooltip } from 'antd';
import { Select } from 'antd';
import { Input } from 'antd';
import { Col } from 'antd';
import { Alert } from 'antd';
import { FormInstance } from 'antd/lib/form';
import { ReactElement, useEffect, useState } from 'react';
import CcxComponentProps from '../../../core/CcxComponent';
import CloudProvider from '../../../types/CloudProvider';
import DeploymentOptions from '../../../types/DeploymentOptions';
import Vpc from '../../../types/Vpc';
import CardContentAvailabilityZoneMulti from '../../ccx/common/CardContentAvailabilityZoneMulti';
import styles from './WizardFormConfigurationStep4.module.less';
import { useAppSelector } from '../../../redux/hooks';
import { RootState } from '../../../redux/store';

interface Props extends CcxComponentProps {
    deploymentOptions?: DeploymentOptions;
    cloudProvider?: CloudProvider;
    handleNetworkChange: Function;
    network: any;
    selectedNetwork: any;
    setNetwork: Function;
    handleNetworkTypeChange: Function;
    networkType: any;
    numberOfNodes: number;
    handleVpcChange: Function;
    vpcs?: Vpc[];
    availabilityZones: any;
    zones: any;
    handleAvailabilityZoneChange: Function;
    form: FormInstance;
    StepsExtra: Function;
}

export function WizardFormConfigurationStep4({
    testId = 'WizardFormConfigurationStep4',
    deploymentOptions,
    cloudProvider,
    handleNetworkChange,
    network,
    selectedNetwork,
    setNetwork,
    handleNetworkTypeChange,
    networkType,
    numberOfNodes,
    handleVpcChange,
    vpcs,
    availabilityZones,
    zones,
    handleAvailabilityZoneChange,
    form,
    StepsExtra,
}: Props): ReactElement {
    const { isBackupRestore, restoreDatastore } = useAppSelector(
        (state: RootState) => state.restoreBackup
    );
    const [localAz, setLocalAz] = useState<string>();
    const [localNetworkType, setLocalNetworkType] =
        useState<string>(networkType);

    useEffect(() => {
        if (networkType !== localNetworkType) {
            form.setFieldsValue({
                networkType,
            });
            setLocalNetworkType(networkType);
        }
    }, [networkType]);

    useEffect(() => {
        const {
            availabilityZones: selectedAvailabilityZones,
            networkCode: selectedNetworkCode,
            networkType: selectedNetworkType,
            vpcUuid,
            availabilityZones1,
            availabilityZones2,
            availabilityZones3,
        } = form.getFieldsValue();
        if (!selectedAvailabilityZones && !selectedNetworkType) {
            setRestoreBackupValues();
        } else {
            const tempNetworkType =
                selectedNetworkType || localNetworkType || networkType;
            setLocalNetworkType(tempNetworkType);
            setLocalAz(zones?.[0]);
            form.setFieldsValue({
                availabilityZones: selectedAvailabilityZones,
                networkType: tempNetworkType,
                networkCode: selectedNetworkCode,
                vpcUuid,
                availabilityZones1,
                availabilityZones2,
                availabilityZones3,
            });
        }
    }, [isBackupRestore, cloudProvider]);

    const setRestoreBackupValues = () => {
        if (
            isBackupRestore &&
            cloudProvider &&
            restoreDatastore?.cloudProvider === cloudProvider.code
        ) {
            const selectedVpc = vpcs?.find((v: Vpc) => {
                return v.id === restoreDatastore?.vpcUuid;
            });

            const restoreNetworkType =
                restoreDatastore?.availabilityZones?.every(
                    (az: any) => az === restoreDatastore?.availabilityZones?.[0]
                );
            setLocalNetworkType(restoreNetworkType ? 'regular' : 'ha');
            const availabilityZones = restoreDatastore?.availabilityZones || [];
            const availabilityZoneFields = availabilityZones.reduce(
                (acc: any, az: any, i: number) => {
                    acc[`availabilityZones${i + 1}`] = az;
                    return acc;
                },
                {}
            );

            if (restoreNetworkType) {
                setLocalAz(restoreDatastore?.availabilityZones?.[0]);
            }
            form.setFieldsValue({
                networkCode: restoreDatastore?.networkType,
                vpcUuid: selectedVpc?.id,
                networkType: restoreNetworkType ? 'regular' : 'ha',
                availabilityZones: availabilityZones,
                ...availabilityZoneFields,
            });

            handleNetworkChange({
                target: {
                    value: restoreDatastore?.networkType,
                },
            });
            handleVpcChange(selectedVpc?.id);
            onAvailabilityZoneChange({
                target: { value: restoreDatastore?.availabilityZones },
            });
        }
    };

    const onAvailabilityZoneChange = (e: any) => {
        handleAvailabilityZoneChange &&
            handleAvailabilityZoneChange(0, e.target.value);
        if (e.target.value.length > 0) {
            setLocalAz(e.target.value[0]);
        } else {
            setLocalAz(e.target.value);
        }
    };

    const handleMultiAvailabilityZoneChange = (
        instance: number,
        value: any
    ) => {
        handleAvailabilityZoneChange &&
            handleAvailabilityZoneChange(instance, value);
    };

    return cloudProvider ? (
        <div data-testid={testId}>
            <p>
                <strong>Network type</strong>
            </p>
            <Alert
                message={
                    <>
                        Network type <strong>cannot be changed</strong> after
                        deployment.
                    </>
                }
                type="warning"
                showIcon
            />

            <Form.Item
                name="networkCode"
                label=""
                rules={[
                    {
                        required: true,
                        message: 'Select your network',
                    },
                ]}
            >
                <Radio.Group
                    data-testid={`${testId}NetworkSettingsRadio`}
                    className={styles.WizardFormConfigurationStep4Group}
                    onChange={handleNetworkChange as any}
                >
                    <Row gutter={16}>
                        {deploymentOptions
                            ?.getNetworks(cloudProvider)
                            ?.map((n: any) => {
                                return (
                                    <Col
                                        key={`${testId}${n.code}NetworkCol`}
                                        span={12}
                                    >
                                        <Radio.Button
                                            data-testid={`${testId}NetworkRadio${n.code}`}
                                            disabled={!n.enabled}
                                            value={n.code}
                                            key={n.code}
                                            className={
                                                styles.WizardFormConfigurationStep4RadioItem
                                            }
                                        >
                                            <div
                                                className={
                                                    styles.WizardFormConfigurationStep4Item
                                                }
                                            >
                                                <small>
                                                    <Tooltip
                                                        placement="bottom"
                                                        title={
                                                            n.enabled
                                                                ? n.info
                                                                : null
                                                        }
                                                    >
                                                        <InfoCircleOutlined />
                                                    </Tooltip>
                                                </small>
                                                {n.name === 'Public' ? (
                                                    <EyeOutlined />
                                                ) : (
                                                    <EyeInvisibleOutlined />
                                                )}
                                                <span>{n.name}</span>
                                            </div>
                                        </Radio.Button>
                                    </Col>
                                );
                            })}
                    </Row>
                </Radio.Group>
            </Form.Item>

            {selectedNetwork?.code && (
                <>
                    {numberOfNodes > 1 && (
                        <>
                            <p>
                                <strong>
                                    Availability zone{' '}
                                    <Tooltip
                                        placement="bottom"
                                        title={deploymentOptions?.getNetworkTypeInfo(
                                            cloudProvider
                                        )}
                                    >
                                        <InfoCircleOutlined />
                                    </Tooltip>
                                </strong>
                            </p>
                            <Form.Item
                                name="networkType"
                                label=""
                                rules={[
                                    {
                                        required: true,
                                        message: 'Select Network Type',
                                    },
                                ]}
                            >
                                <Radio.Group
                                    className={
                                        styles.WizardFormConfigurationStep4Group
                                    }
                                    data-testid={`${testId}NetworkTypeRadio`}
                                    onChange={(e) => {
                                        setLocalNetworkType(e.target.value);
                                        handleNetworkTypeChange(e);
                                    }}
                                >
                                    <Row gutter={16}>
                                        <Col span={12}>
                                            <Radio.Button
                                                className={
                                                    styles.WizardFormConfigurationStep4RadioTypeItem
                                                }
                                                data-testid={`${testId}NetworkRadio${'regular'}`}
                                                value={'regular'}
                                            >
                                                <div
                                                    className={
                                                        styles.WizardFormConfigurationStep4NetworkTypeItem
                                                    }
                                                >
                                                    <span>Single AZ</span>
                                                </div>
                                            </Radio.Button>
                                        </Col>
                                        <Col span={12}>
                                            <Radio.Button
                                                className={
                                                    styles.WizardFormConfigurationStep4RadioTypeItem
                                                }
                                                data-testid={`${testId}NetworkRadio${'ha'}`}
                                                value={'ha'}
                                            >
                                                <div
                                                    className={
                                                        styles.WizardFormConfigurationStep4NetworkTypeItem
                                                    }
                                                >
                                                    <span>Multi AZ</span>
                                                </div>
                                            </Radio.Button>
                                        </Col>
                                    </Row>
                                </Radio.Group>
                            </Form.Item>
                        </>
                    )}

                    {network?.vpc_available && localNetworkType && (
                        <Form.Item
                            name="vpcUuid"
                            label="VPC"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please select a VPC',
                                },
                            ]}
                        >
                            <Select
                                data-testid={`${testId}VPCSelect`}
                                onChange={handleVpcChange as any}
                                placeholder="Select a VPC or Create a new one"
                                className={
                                    styles.WizardFormConfigurationStep4VPC
                                }
                            >
                                {vpcs?.map((v: Vpc) => {
                                    return (
                                        <Select.Option
                                            value={v.id}
                                            key={v.id}
                                        >{`${v.getName()} - ${v.getCidrIpv4Block()}`}</Select.Option>
                                    );
                                })}
                                <Select.Option value="new" key="new">
                                    Create new VPC
                                </Select.Option>
                            </Select>
                        </Form.Item>
                    )}

                    {localNetworkType &&
                        (availabilityZones.length ||
                            restoreDatastore?.availabilityZones?.length) >
                            1 && (
                            <>
                                <p>Select a zone</p>
                                <Form.Item
                                    name="availabilityZones"
                                    label=""
                                    rules={[
                                        {
                                            required: true,
                                            message:
                                                'Select availability zones',
                                        },
                                    ]}
                                >
                                    {localNetworkType === 'regular' ? (
                                        <Radio.Group
                                            data-testid={`${testId}AvailabilityZones`}
                                            onChange={onAvailabilityZoneChange}
                                            value={localAz}
                                            className={
                                                styles.WizardFormConfigurationStep4Group
                                            }
                                        >
                                            <Row gutter={16}>
                                                {availabilityZones?.map(
                                                    (az: any) => {
                                                        return (
                                                            <Col
                                                                key={`${testId}${az.code}AvailabilityZoneSingle`}
                                                                xs={12}
                                                                sm={8}
                                                            >
                                                                <Radio.Button
                                                                    defaultChecked={
                                                                        zones &&
                                                                        zones[0]
                                                                    }
                                                                    data-testid={`${testId}AvailabilityZoneSingle${az.code}`}
                                                                    key={
                                                                        az.code
                                                                    }
                                                                    value={
                                                                        az.code
                                                                    }
                                                                    className={
                                                                        styles.WizardFormConfigurationStep4RadioTypeItem
                                                                    }
                                                                >
                                                                    <div
                                                                        className={
                                                                            styles.WizardFormConfigurationStep4NetworkTypeItem
                                                                        }
                                                                    >
                                                                        <span>
                                                                            {
                                                                                az.name
                                                                            }
                                                                        </span>
                                                                    </div>
                                                                </Radio.Button>
                                                            </Col>
                                                        );
                                                    }
                                                )}
                                            </Row>
                                        </Radio.Group>
                                    ) : (
                                        <CardContentAvailabilityZoneMulti
                                            availabilityZones={
                                                availabilityZones
                                            }
                                            numberOfNodes={numberOfNodes}
                                            onChange={
                                                handleMultiAvailabilityZoneChange
                                            }
                                            selectedZones={zones}
                                            testId={`${testId}AvailabilityZoneMulti`}
                                            radio={false}
                                        />
                                    )}
                                    <Input type="hidden" />
                                </Form.Item>
                            </>
                        )}
                </>
            )}

            <div className={styles.StepsExtraSmallScreen}>
                <StepsExtra />
            </div>
        </div>
    ) : (
        <></>
    );
}

export function isOnlyPublicNetworkEnabled(networks: any[]) {
    const availableNetworks = networks.filter((n) => n.enabled);
    return (
        availableNetworks.length === 1 && availableNetworks[0].code === 'public'
    );
}
